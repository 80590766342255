import React, { useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import { useSiteData } from '../hooks/site';
import { useCompactLogo } from '../hooks/logos';
import { useIcons } from '../hooks/icons';
import { useFlags } from '../hooks/flags';
import SVG from 'react-inlinesvg';

const Header: React.FC = () => {
  const site = useSiteData();
  const compactLogo = useCompactLogo();
  const icons = useIcons();
  const flags = useFlags();

  const toggleMenu = (isMenuButton: boolean) => {
    const bodyClass = document.body.classList;

    // Don't let the logo open the menu
    if (!isMenuButton && !bodyClass.contains('menu-open')) {
      return null;
    }

    // Otherwise, If it's a mobile device, let's open the menu
    if (window.innerWidth <= 768) {
      bodyClass.toggle('menu-open');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', function () {
      if (window.innerWidth >= 768) {
        document.body.classList.remove('menu-open');
      }
    });
  }, []);

  return (
    <HeaderContainer className="site-header">
      <div className="container">
        <h1 className="logo">
          <Link to="/" onClick={() => toggleMenu(false)}>
            <img src={compactLogo.publicURL} alt={site.siteMetadata.title} />
          </Link>
        </h1>
        <div className="menu-nav">
          <nav className="primary-nav" aria-label="Primary Navigation">
            <ul>
              {flags.hide.has('research') || (
                <li>
                  <Link to="/research/" onClick={toggleMenu}>
                    Research
                  </Link>
                </li>
              )}
              {flags.hide.has('contributions') || (
                <li>
                  <Link to="/contributions/" onClick={toggleMenu}>
                    Contributions
                  </Link>
                </li>
              )}
              {flags.hide.has('contributions') || (
                <li>
                  <Link to="/contracts/" onClick={toggleMenu}>
                    Contracts
                  </Link>
                </li>
              )}
              {flags.hide.has('about') || (
                <li>
                  <Link to="/about" onClick={toggleMenu}>
                    About
                  </Link>
                </li>
              )}
              {flags.hide.has('podcast') || (
                <li>
                  <a
                    className="podcast-link"
                    href="https://www.lemonadamedia.com/show/the-untold-story-policing/"
                    onClick={() => {
                      ga('send', 'event', 'action', 'podcast', 'Podcast');
                      fbq('trackCustom', 'PodcastLink');
                    }}
                  >
                    <span>Podcast</span>
                    <SVG
                      className="podcast-icon"
                      src={icons.podcast.publicURL}
                      width="25"
                      height="25"
                    />
                  </a>
                </li>
              )}
            </ul>
          </nav>

          <nav className="secondary-nav" aria-label="Secondary Navigation">
            <ul>
              <li className="project-by">
                A project by{' '}
                <a href="https://www.joincampaignzero.org/">Campaign Zero</a>
              </li>
              <li>
                <a href={constants.volunteerUrl}>Volunteer</a>
              </li>
              <li>
                <a
                  href={`#FUNWMBYRMRW`}
                  onClick={() => {
                    ga('send', 'event', 'action', 'donate', 'Donate');
                    fbq('trackCustom', 'DonateLink');
                  }}
                >
                  Donate
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <button
          type="button"
          className="btn-menu"
          onClick={() => toggleMenu(true)}
        >
          <span>Menu</span>
          <SVG className="btn-menu-icon" src={icons.hamburger.publicURL} />
          <SVG className="btn-menu-close" src={icons.close.publicURL} />
        </button>
      </div>
    </HeaderContainer>
  );
};

// Styled components
const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 1rem 25px;
  background-color: ${constants.headerBgColor};

  .container {
    display: flex;
    align-items: center;
    max-width: 1240px;
    margin: 0 auto;
  }

  // Mobile
  @media (max-width: 767.98px) {
    background: ${constants.highlightForegroundColor};

    .container {
      align-items: flex-start;
      height: 100%;
    }

    .menu-open & {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      height: 100%;
      width: 100%;
    }
  }

  ul {
    list-style: none;
  }

  .logo {
    margin: 0 2rem 0 0;

    @media (max-width: 767.98px) {
      margin-right: 0;

      .menu-open & {
        position: absolute;
        top: 1rem;
        left: 25px;
      }
    }
  }

  // Menu Navigation
  .menu-nav {
    display: flex;
    width: 100%;

    @media (max-width: 767.98px) {
      display: none;
      .menu-open & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }

  .primary-nav {
    display: flex;
    align-items: center;
    font-size: 18px;

    @media (max-width: 767.98px) {
      text-align: center;
      margin: auto 0;
    }

    ul {
      padding: 0;
      position: relative;
      display: flex;
      align-items: center;

      @media (max-width: 767.98px) {
        margin: 0 auto 2rem;
        flex-direction: column;
      }

      li {
        margin-right: 1em;

        @media (max-width: 767.98px) {
          display: block;
          margin-right: 0;
          text-align: center;
        }

        a {
          text-decoration: none;
          transition: ${constants.colorTransition};
          color: #fff;

          @media (max-width: 767.98px) {
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            line-height: 52px;
          }

          &:hover {
            color: ${constants.highlightColor};
          }
        }

        .podcast-link {
          display: flex;
          align-items: center;

          @media (max-width: 767.98px) {
            display: block;
          }

          .podcast-icon {
            margin-left: 10px;

            @media (max-width: 767.98px) {
              display: none;
              margin-left: 0;
            }

            path {
              transition: fill linear 150ms;
            }
          }

          &:hover {
            .podcast-icon path {
              fill: ${constants.highlightColor};
            }
          }
        }
      }
    }
  }

  .secondary-nav {
    margin-left: auto;
    font-size: 14px;
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
      margin-left: 0;
      text-align: center;
    }

    ul {
      padding: 0;
      display: flex;

      @media (max-width: 767.98px) {
        flex-direction: column;
        margin: 0 auto;
      }
    }

    .project-by {
      margin-right: 2em;
      color: #fff;

      @media (max-width: 767.98px) {
        margin-right: 0;
        margin-bottom: 2rem;
      }

      a {
        text-decoration: none;
        font-weight: bold;
        color: #fff;
        text-transform: capitalize;
        position: relative;

        &:hover {
          color: ${constants.highlightColor};
        }
      }
    }

    li {
      margin-right: 1em;

      @media (max-width: 767.98px) {
        display: block;
        margin-right: 0;
        margin-bottom: 1rem;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 15px;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        color: ${constants.highlightColor};
        transition: ${constants.colorTransition};

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .btn-menu {
    display: none;

    &:focus {
      outline: none;
    }

    @media (max-width: 767.98px) {
      display: block;
      background-color: transparent;
      border: 2px solid white;
      box-sizing: border-box;
      color: ${constants.highlightColor};
      cursor: pointer;
      display: flex;
      align-items: center;
      overflow: visible;
      padding: 10px 16px;
      font-size: 15px;
      letter-spacing: 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      user-select: none;
      vertical-align: middle;
      margin-left: auto;

      span {
        display: block;
        margin-right: 1rem;
      }
    }

    &-icon {
      display: block;
      width: 48px;
      height: 24px;
    }

    &-close {
      display: none;
      width: 37px;
      height: 37px;
    }

    // Modal Open
    .menu-open & {
      border: none;
      position: absolute;
      top: 1rem;
      right: 25px;
      padding: 0;

      > span,
      &-icon {
        display: none;
      }

      &-close {
        display: block;
      }
    }
  }
`;

export default Header;
