import showdown from 'showdown';

import {
  Location,
  LocationKind,
  RemoteAsset,
  Contract,
  Person,
  Role,
  Issue,
  IssueType,
  PollResult,
  Poll,
  ResearchLink,
  ResearchLinkKind,
} from './interfaces';

export interface AirtableLocation {
  _id?: string;
  name: string;
  short_name: null | string;
  kind: LocationKind;
  contained_by: AirtableRelation<AirtableLocation>;
  image: AttachmentField;
  contracts: AirtableRelation<AirtableContract>;
  repealed: string;
  officials: AirtableRelation<AirtablePerson>;
  legislator_role: AirtableRelation<AirtableRole>;
  poll_results: AirtableRelation<AirtablePollResult>;
  has_police_bill_of_rights: null | true;
  police_union_says: null | string;
  involvement_url: null | string;
  local_org_url: null | string;
  fired_officers_data_url: null | string;
  officer_rehiring_rate: null | number;
}

export interface AirtablePollResult {
  result: null | number;
  poll: AirtableRelation<AirtablePoll>;
}

export interface AirtablePoll {
  name: string;
  description: string;
}

export interface AirtableContract {
  id: string;
  name: string;
  expiration_date: null | string;
  location: AirtableRelation<AirtableLocation>;
  roles: AirtableRelation<AirtableRole>;
  document: AttachmentField;
  external_url: null | string;
  issues: AirtableRelation<AirtableIssue>;
}

export interface AirtableIssue {
  type: AirtableRelation<AirtableIssueType>;
  description: null | string;
  tags: null | string;
}

export interface AirtableIssueType {
  name: string;
}

export interface AirtablePerson {
  name: string;
  email: null | string;
  phone: null | string;
  twitter: null | string;
  website: null | string;
  roles: AirtableRelation<AirtableRole>;
}

export interface AirtableRole {
  name: string;
}

export interface AirtableContentBlock {
  key: string;
  text: null | string;
  image: AttachmentField;
  link: null | string;
}

export interface AirtableResearchLink {
  name: string;
  url: string;
  thumbnail: null | AttachmentField;
  kind: string;
  published: string;
}

type AirtableRelation<T> =
  | null
  | {
      data: T;
    }[];

export interface AirtableAttachment {
  url: string;
}

type AttachmentField = null | AirtableAttachment[];

export const markdown = new showdown.Converter();

export const mapRelation = <TInput, TOutput>(
  mapper: (input: TInput) => TOutput,
  field: AirtableRelation<TInput>
) => {
  return field ? field.map(node => mapper(node.data)) : [];
};

export const mapRemoteAssets = (field: AttachmentField): RemoteAsset[] => {
  return (field || []) as RemoteAsset[];
};

export const mapLocation = (input: AirtableLocation): Location => {
  const location: Location = {
    // Scalar mappings
    name: input.name,
    shortName: input.short_name || undefined,
    kind: input.kind,
    hasPoliceBillOfRights: input.has_police_bill_of_rights === true,
    policeUnionSays: input.police_union_says
      ? markdown.makeHtml(input.police_union_says)
      : undefined,
    involvementUrl: input.involvement_url || undefined,
    localOrgUrl: input.local_org_url || undefined,
    firedOfficersDataUrl: input.fired_officers_data_url || undefined,
    officerRehiringRate: input.officer_rehiring_rate || undefined,
    repealed: input.repealed || undefined,

    // Relation mappings
    image: mapRemoteAssets(input.image)[0],

    legislatorRole: mapRelation<AirtableRole, Role>(
      mapRole,
      input.legislator_role
    )[0],

    officials: mapRelation<AirtablePerson, Person>(mapPerson, input.officials),

    containedBy: mapRelation<AirtableLocation, Location>(
      mapLocation,
      input.contained_by
    ),

    contracts: [],

    pollResults: mapRelation<AirtablePollResult, PollResult>(
      mapPollResult,
      input.poll_results
    ),
  };

  // contract.location not resolving via plugin (circular reference?)
  // Adding it manually here.
  location.contracts = input.contracts
    ? input.contracts
        .map(({ data }) => {
          return {
            ...mapContract(data),
            location,
          };
        })
        .sort(sortContractsByExpirationDate)
    : [];

  return location;
};

export const mapPollResult = (input: AirtablePollResult): PollResult => ({
  poll: mapRelation<AirtablePoll, Poll>(mapPoll, input.poll)[0],
  result: input.result || 0,
});

export const mapPoll = (input: AirtablePoll): Poll => ({
  ...input,
});

export const sortContractsByExpirationDate = (
  a: Contract,
  b: Contract
): number => {
  if (!a.expirationDate) {
    return 1;
  }

  if (!b.expirationDate) {
    return -1;
  }

  return a.expirationDate.valueOf() - b.expirationDate.valueOf();
};

export const mapContract = (input: AirtableContract): Contract => ({
  id: input.id || undefined,

  name: input.name,

  roles: mapRelation<AirtableRole, Role>(mapRole, input.roles),

  document: mapRemoteAssets(input.document)[0],

  externalUrl: input.external_url || undefined,

  expirationDate: input.expiration_date
    ? new Date(input.expiration_date)
    : undefined,

  location: mapRelation<AirtableLocation, Location>(
    mapLocation,
    input.location
  )[0],

  issues: mapRelation<AirtableIssue, Issue>(
    mapIssue,
    input.issues
  ).filter(issue => Boolean(issue.type)),
});

export const mapPerson = (input: AirtablePerson): Person => ({
  name: input.name,
  email: input.email || undefined,
  twitter: input.twitter || undefined,
  phone: input.phone || undefined,
  website: input.website || undefined,
  roles: mapRelation<AirtableRole, Role>(mapRole, input.roles),
});

export const mapRole = (input: AirtableRole): Role => ({
  ...input,
});

export const mapIssue = (input: AirtableIssue): Issue => ({
  type: mapRelation<AirtableIssueType, IssueType>(mapIssueType, input.type)[0],
  description: markdown.makeHtml(input.description || ''),
  tags: input.tags || undefined,
});

export const mapIssueType = (input: AirtableIssueType): IssueType => ({
  ...input,
});

export const mapResearchLink = (input: AirtableResearchLink): ResearchLink => ({
  name: input.name,
  url: input.url,
  thumbnail: mapRemoteAssets(input.thumbnail)[0],
  kind: input.kind as ResearchLinkKind,
  published: new Date(input.published),
});
