type HideFlagKey =
  | 'research'
  | 'contributions'
  | 'about'
  | 'podcast'
  | 'state_pages'
  | 'city_pages';

export const useFlags = (): {
  hide: Set<HideFlagKey>;
} => {
  const hideFlags: HideFlagKey[] = process.env.GATSBY_FLAG_HIDE
    ? (process.env.GATSBY_FLAG_HIDE.split(/, */) as HideFlagKey[])
    : [];

  return {
    hide: new Set(hideFlags),
  };
};
