import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { AirtableResearchLink, mapResearchLink } from '../airtable-mapping';

export const ThumbnailContainer = ({
  image,
}: {
  image: {
    thumbnail: {
      url: string;
    };
  };
}) => {
  if (image.thumbnail) {
    return (
      <div>
        <img src={image.thumbnail.url} alt="Thumbnail" />
      </div>
    );
  }
  return null;
};

const ResearchPage: React.FC<{
  pageContext: {
    airtableResearchLinks: AirtableResearchLink[];
  };
}> = ({ pageContext }) => {
  const items = pageContext.airtableResearchLinks.map(input => {
    const link = mapResearchLink(input);
    return (
      <li key={link.name}>
        <a href={link.url}>
          <ThumbnailContainer image={link} />
          <span className="title">{link.name}</span>
        </a>
      </li>
    );
  });

  return (
    <Layout>
      <SEO title="Research" />
      <ResearchContainer>
        <h2 className="page-title">Research</h2>

        <section className="content">
          <p className="intro">
            An emerging research literature establishes that police unions, and
            the police union contracts and laws they’ve worked to enact, are
            associated with higher rates of police violence and lower levels of
            police accountability. Read our{' '}
            <Link to="/research-basis">Research Basis to learn more</Link> about
            the research informing this project. We’ve compiled a list of
            research studies, articles and reports on police unions and police
            union contracts below.
          </p>

          <ul className="research-items">{items}</ul>
        </section>
      </ResearchContainer>
    </Layout>
  );
};

export const ResearchContainer = styled.div`
  .page-title {
    text-align: center;
    margin-bottom: 1em;

    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 1.375;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5em;
  }

  .content {
    max-width: 1184px;
    margin: 0 auto;
    padding: 80px 104px;
    background: #fff;
    color: ${constants.contentTextColor};
    border-radius: 8px;

    @media (max-width: 767.98px) {
      padding: 40px 1rem;
      border-radius: 0;
    }
  }

    .intro {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 2em;
    }

    .research-items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1rem;
      margin-right: -1rem;
      list-style: none;
      padding: 0;

      li {
        flex: 0 0 50%;
        padding: 0 1rem 2rem 1rem;

        @media (min-width: 576px) {
          flex: 0 0 33%;
        }

        @media (min-width: 992px) {
          flex: 0 0 20%;
        }

        a {
          display: block;
          text-decoration: none;
          transition: ${constants.colorTransition};
          
          > div {
            position: relative;
            width: 100%;
            border: 1px solid #ccc;
            overflow: hidden;
            margin-bottom: 0.5rem;
            max-height: 130px;

            &::before {
              padding-top: 50%;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .title {
            display: block;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
          }

          &:hover {
            color: ${constants.highlightColor};
          }
        }
      }
    }
  }
`;

export default ResearchPage;
