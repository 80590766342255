import React from 'react';
import styled from '@emotion/styled';
import GlobalStyles from './global-styles';
import Header from './header';
// import Banner from './banner';
import Footer from './footer';
import Hero from './hero';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Header />
      <MainContainer>
        <Hero />
        {children}
      </MainContainer>
      <Footer />
    </>
  );
};

export const MainContainer = styled.main``;

export default Layout;
