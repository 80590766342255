import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import { useIcons } from '../hooks/icons';
import SVG from 'react-inlinesvg';

const Footer: React.FC = () => {
  const icons = useIcons();

  return (
    <FooterContainer>
      <div className="signup-form">
        <form
          action="https://8cantwait.us5.list-manage.com/subscribe/post?u=81643c9592bb041cc8a4ea614&amp;id=eaa4e8a073"
          method="post"
          name="mc-embedded-subscribe-form"
        >
          <div className="form-row">
            <label htmlFor="mce-EMAIL" className="visually-hidden">
              Email Address
            </label>
            <input
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              placeholder="Email Address"
              className="form-control"
            />
            <input
              type="submit"
              value="Stay Updated"
              name="subscribe"
              className="button"
            />
            <input
              type="text"
              name="b_81643c9592bb041cc8a4ea614_eaa4e8a073"
              tabIndex="-1"
              className="display-none"
            />
          </div>
        </form>
      </div>
      <nav className="social-nav">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/joincampaignzero/"
              aria-label="Facebook"
            >
              <SVG src={icons.facebook.publicURL} width="30" height="30" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/CampaignZero/"
              aria-label="Instagram"
            >
              <SVG src={icons.instagram.publicURL} />
            </a>
          </li>
        </ul>

        <p className="email">
          <a href="mailto:community@nixthe6.org">community@nixthe6.org</a>
        </p>
      </nav>
    </FooterContainer>
  );
};

export const FooterContainer = styled.footer`
  max-width: 1184px;
  margin: 0 auto;
  padding: 60px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }

  .signup-form {
    width: 40%;

    @media (max-width: 767.98px) {
      width: 100%;
    }

    .form-row {
      display: flex;
      align-items: center;

      @media (max-width: 767.98px) {
        flex-direction: column;
        margin-bottom: 2rem;
      }
    }

    #mce-EMAIL {
      background-color: transparent;
      border: 0;

      background-clip: padding-box;
      box-sizing: border-box;
      cursor: default;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      overflow-wrap: normal;
      text-transform: none;
      white-space: pre;
      width: 100%;
      padding: 10px 0;
      border-bottom: 5px solid rgba(118, 118, 118, 0.45);
      transition: all linear 150ms;

      &:active,
      &:focus {
        border-bottom-color: #fff;
        outline: none;

        &::placeholder {
          color: #fff;
        }
      }

      @media (max-width: 767.98px) {
        margin-bottom: 1rem;
      }
    }

    .button {
      background: #ff921e;
      border-radius: 4px;
      padding: 15px;
      font-size: 1rem;
      color: #231f20;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      border: 0;
      margin-left: 1rem;
      transition: all linear 150ms;

      &:hover {
        background: #ff921e;
        color: #fff;
        cursor: pointer;
      }

      @media (max-width: 767.98px) {
        margin-left: 0;
      }
    }
  }

  .social-nav {
    width: auto;
    margin-left: auto;

    @media (max-width: 767.98px) {
      width: 100%;
      text-align: center;
    }

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;

      @media (max-width: 767.98px) {
        justify-content: center;
      }

      li {
        margin-left: 30px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    .email {
      display: block;
      font-size: 18px;
      font-weight: bold;
      color: ${constants.highlightColor};
      text-decoration: none;
      margin-top: 1em;

      a {
        text-decoration: none;
        transition: color linear 150ms;

        &:hover {
          color: #fff;
        }
      }
    }
  }
`;

export default Footer;
