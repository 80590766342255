import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import socialShare from '../images/social-share.jpg';

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

function SEO({
  description,
  ogdescription,
  twitterdescription,
  lang,
  meta,
  title,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            ogdescription
            twitterdescription
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const ogDescription =
    ogdescription || description || site.siteMetadata.description;
  const twitterDescription =
    twitterdescription || description || site.siteMetadata.description;
  const defaultTitle = `${site.siteMetadata.title} | Campaign Zero`;
  const theTitle = title
    ? `${title} | ${site.siteMetadata.title} | Campaign Zero`
    : defaultTitle;
  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';
  const originUrl = typeof window !== 'undefined' ? window.location.origin : '';
  const socialImageUrl = originUrl + socialShare;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={theTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: theTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:image`,
          content: socialImageUrl,
        },
        {
          property: `twitter:image`,
          content: socialImageUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: pageUrl,
        },
        {
          property: `twitter:url`,
          content: pageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: theTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
