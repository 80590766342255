import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useFullLogo } from '../hooks/logos';
import SVG from 'react-inlinesvg';

const Hero: React.FC = () => {
  const logo = useFullLogo();

  return (
    <HeroContainer>
      <h2>
        <Link to="/">
          <SVG src={logo.publicURL} aria-label="Nix The 6" />
        </Link>
      </h2>
      <p className="subheader">
        Police unions have too much power. It’s time to hold them accountable.
      </p>
    </HeroContainer>
  );
};

export const HeroContainer = styled.section`
  text-align: center;
  max-width: 610px;
  margin: 60px auto;

  h2 {
    margin-bottom: 10px;

    svg {
      width: 100%;

      @media (max-width: 767.98px) {
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  .subheader {
    text-transform: uppercase;
    padding: 0 40px;
    font-size: 21px;
    color: #ffffff;
    letter-spacing: 0.48px;
    text-align: center;
    line-height: 28px;

    @media (max-width: 767.98px) {
      font-size: 15px;
      letter-spacing: 0.34px;
      line-height: 20px;
    }
  }
`;

export default Hero;
