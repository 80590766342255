import { useStaticQuery, graphql } from 'gatsby';

export interface SiteData {
  siteMetadata: {
    title: string;
  };
}

export const useSiteData = (): SiteData => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return data.site;
};
